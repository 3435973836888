(function ($) {
  "use strict"; // Start of use strict

  // Toggle the side navigation
//  $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
//    $("body").toggleClass("sidebar-toggled");
//    $(".sidebar").toggleClass("toggled");
//    if ($(".sidebar").hasClass("toggled")) {
//      $('.sidebar .collapse').collapse('hide');
//    };
//  });

  // Close any open menu accordions when window is resized below 768px
//  $(window).resize(function () {
//    if ($(window).width() < 768) {
//      $('.sidebar .collapse').collapse('hide');
//    };
//  });

  // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
  $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
    if ($(window).width() > 768) {
      var e0 = e.originalEvent,
        delta = e0.wheelDelta || -e0.detail;
      this.scrollTop += (delta < 0 ? 1 : -1) * 30;
      e.preventDefault();
    }
  });

  // Scroll to top button appear
  $(document).on('scroll', function () {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
      $('.scroll-to-top').fadeIn();
    } else {
      $('.scroll-to-top').fadeOut();
    }
  });

  // Smooth scrolling using jQuery easing
  $(document).on('click', 'a.scroll-to-top', function (e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
      scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, ''); //easeInOutExpo
    e.preventDefault();
  });
    
  
  // Fixed nav on mobile only
  function stickyHeader() {
      var headerHeight = $('#header').outerHeight(true);
      if ($(document).width() < 576) {
          $('#header').addClass('fixed-top');
          $('#content').css('paddingTop',headerHeight);
        } else {
          $('#header').removeClass('fixed-top');
          $('#content').css('paddingTop',0);  
      }
  }
  stickyHeader(); 
  $(window).on('resize', function () {
    stickyHeader();    
  });

  // Initiate AOS
  //AOS.init();


  // Select2 - generic ones
    $('.select2').select2({
      placeholder: function () {
        $(this).data('placeholder');
        $(this + " .select2-search__field").css('width', '100%');
      }
    });

    $(".select2.nosearch").select2({
      minimumResultsForSearch: Infinity,
      placeholder: function () {
        $(this).data('placeholder');
        $(this + " .select2-search__field").css('width', '100%');
      }
    });

    $(".select2.multiple").select2({
      tokenSeparators: [','],
      placeholder: function () {
        $(this).data('placeholder');
        $(this + " .select2-search__field").css('width', '100%');
      }
    });
      
    $(".select2.multiple-with-add").select2({
      tags: true,
      tokenSeparators: [','],
      placeholder: function () {
        $(this).data('placeholder');
        $(this + " .select2-search__field").css('width', '100%');
      }
    });



  // Initiate datetime picker
  $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    icons: {
      time: 'far fa-clock',
      date: 'fas fa-calendar',
      up: 'far fa-arrow-up',
      down: 'far fa-arrow-down',
      previous: 'far fa-chevron-left',
      next: 'far fa-chevron-right',
      today: 'far fa-calendar-check-o',
      clear: 'far fa-trash',
      close: 'far fa-times'
    },
    format: 'YYYY/MM/DD', //added default format
    //debug: true
  });
    
  $('.date .input-group-append').datetimepicker().on('dp.show', function() {
  $(this).closest('.table-responsive').removeClass('table-responsive').addClass('temp');
  }).on('dp.hide', function() {
  $(this).closest('.temp').addClass('table-responsive').removeClass('temp')
  });    
    
  //MODALS
  //ADD PRICETYPE
  $(document).on("click", ".modal-pricetype", function () {
    $('#addPriceTypeModal').modal('show');
  });
    
  //ADD/EDIT COUNTRY RULE
   $(document).on("click", ".modal-countryrule", function () {
    var dataID = $(this).data('id');
    //$("fieldid").val(dataID);
    $("#addeditCountryRuleModal #countryrule-id").html(dataID);
    $('#addeditCountryRuleModal').modal('show');
  }); 
    
  //ADD/EDIT EVENT
  $(document).on("click", ".modal-event", function () {
    var dataID = $(this).data('id');
    $("#addEditEventModal #event-id").html(dataID);
    $('#addEditEventModal').modal('show');
  }); 
    
  //ADD/EDIT REGULATORY DATES
   $(document).on("click", ".modal-regdates", function () {
    var dataID = $(this).data('id');
    //$("fieldid").val(dataID);
    $("#addeditRegDatesModal #regdate-id").html(dataID);
    $('#addeditRegDatesModal').modal('show');
  }); 
    
  //ADD/EDIT PRODUCT MARKETED
   $(document).on("click", ".modal-prodsmkted", function () {
    var dataID = $(this).data('id');
    //$("fieldid").val(dataID);
    $("#addeditProdsMktedModal #prodsmkted-id").html(dataID);
    $('#addeditProdsMktedModal').modal('show');
  });
    
   //ADD Additional Price Types
   $(document).on("click", ".modal-addpricetypes", function () {
    $('#addAdditionalPriceTypesModal').modal('show');
  }); 
    
   //ADD CHANNEL
   $(document).on("click", ".modal-channel", function () {
    $('#addChannelModal').modal('show');
  }); 
    
    //ADD/EDIT 'VALIDATE DATA' RULES
   $(document).on("click", ".modal-valdatarules", function () {
    var dataID = $(this).data('id');
    //$("fieldid").val(dataID);
      
    //DEMO PURPOSES ONLY!
     if (dataID=="NEW"||dataID==null||dataID=="") {
         //$("#addeditValDataRulesModal #addeditvaldatarules-validationtype").val('').trigger('change.select2'); 
         $("#addeditValDataRulesModal #addeditvaldatarules-validationtype").select2({placeholder: 'Select...'}).val(null).change();
         $("#addeditValDataRulesModal #addeditvaldatarules-validationtype").prop("disabled", false);
     } else {
         $("#addeditValDataRulesModal #addeditvaldatarules-validationtype").val('Highest/Lowest In-Market Net Prices').trigger('change.select2');
         $("#addeditValDataRulesModal #addeditvaldatarules-validationtype").prop("disabled", true);  
     }
       
       
    $("#addeditValDataRulesModal #valdatarule-id").html(dataID);
    $('#addeditValDataRulesModal').modal('show');
  });
    
    
   //DELETE ITEM
   $(document).on("click", ".delete-item", function () {
    var dataID = $(this).data('id');
    $("#deleteModal #delete-id").html(dataID);
    $('#deleteModal').modal('show');
   });
    
  //FILE UPLOADED
  $(document).on("click", ".modal-fileupload", function () {
    $('#multiFileUploadModal').modal('show');
  });
    


  // Initiate dataTables
  $('.dataTable').DataTable({
    order: [],
    lengthMenu: [ [10, 25, 50, -1], [10, 25, 50, "All"] ],
    pageLength:25
  });


  var table = $('.dataTable.clickableRow').DataTable();
  $('.dataTable.clickableRow tbody').on('click', 'tr', function () {
    console.log(table.row(this).data());
  });


  //PRICE TYPES SELECT ALL
  $("#editproducts-select-all").click(function () {
    $(".editproducts-select").prop('checked', $(this).prop('checked'));
   });

  //RADIO REIMBURSEMENT % SHOW/HIDE
  $('input[type=radio][name=editproducts-reimbursed]').on('change', function () {
      $('.reimbursedperc').removeClass('d-inline-block');
      $('.reimbursedperc').addClass('d-none');
      $(this).closest('td').find('.reimbursedperc').addClass('d-inline-block');
});

    //show chosen file field name
//    $('.custom-file-input').on('change',function(){
//        var fileName = $(this).val();
//        $(this).next('.custom-file-label').html(fileName);
//    })
    
    $('.custom-file input').change(function (e) {
        var files = [];
        for (var i = 0; i < $(this)[0].files.length; i++) {
            files.push($(this)[0].files[i].name);
        }
        $(this).next('.custom-file-label').html(files.join(', '));
    });
    
    
    
     $('.country-of-origin').hide();
     $('#incl-countries-origin').click(function(){
            if($(this).prop("checked") == true){
                $('.country-of-origin').show();
            }
            else if($(this).prop("checked") == false){
                $('.country-of-origin').hide();
            }
        });
    
    
    
    
   $("#btn-edit-refrules").click(function (e) { 
       $("#show-refrules").hide();
       $("#edit-refrules").show();
       e.preventDefault();
   });
    $("#btn-edit-refrules-cancel").click(function (e) { 
       //clear any settings (?) then...
       $("#edit-refrules").hide();
       $("#show-refrules").show();
        e.preventDefault();
   });
    $("#btn-submit-refrules").click(function (e) { 
       //save anything then...
       $("#edit-refrules").hide();
       $("#show-refrules").show();
        e.preventDefault();
   });                                    
    
    // THIS ISN'T FOOLPROOF - you can unselect a country of origin manually ... need to lock once checked??
     $('#incl-countries-origin-edit').click(function(){
            if($(this).prop("checked") == true){
                 $('#refbasket-references-country option.country-of-origin-edit').attr('selected',true);
                 //$('#refbasket-references-country option.country-of-origin').attr("disabled", true);
                 //$('#refbasket-references-country option.country-of-origin').addClass("locked"); 
                 $('#refbasket-references-country').select2().trigger('change.select2');      
            }
            else if($(this).prop("checked") == false){
                 $('#refbasket-references-country option.country-of-origin-edit').attr('selected',false);
                 //$('#refbasket-references-country option.country-of-origin').attr("disabled", false);
                //$('#refbasket-references-country option.country-of-origin').removeClass("locked");  
                 $('#refbasket-references-country').select2().trigger('change.select2');
            }
        });

    
    
  // Set HighChart Globals
//  Highcharts.setOptions({
//    colors: ['#1C447F', '#0392CB', '#8a8a8a', '#E6E7E7', '#D5D1CE']
//  });
   Highcharts.setOptions({
    colors: ['#002F6C', '#59CBE8', '#111C4E', '#FFA300', '#B52555', '#E35205', '#6B3077', '#00AB8E', '#7A9A01', '#0084D5']
  });
    

    
    
  // TOP BAR HIGHLIGHT FOR MY JBs SITE - .active just need
	var currentpage = location.pathname;
	if (currentpage!='/'){
        //console.log(currentpage.split("/")[1]);
		$('.topnavbar .nav-item a.nav-link[href^="' + currentpage.split("/")[1] + '"]').addClass('active');
	}

    //POPOVERS
//    $('[data-toggle="popover"]').popover({
//      container: 'body',
//      trigger: 'focus'
//    });
    
    //TOOLTIPS
    //$('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').tooltip({ container: 'body' });
    
    
    //LINK TO TAB
    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    } 

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    })
    

})(jQuery); // End of use strict
